<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader @change="handleAreaChange" ref="cascader"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Error_type')">
                <el-select
                  v-model.trim="formInline.correctionType"
                  filterable
                  placeholder
                  size="10"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="park.desc"
                    :value="park.code"
                    :key="park.code"
                    v-for="park in parkType"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
                <my-component
                  ref="parkInput"
                  :areaIds="areaIds"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                ></my-component>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="detailShow(scope.row)"
                v-if="$route.meta.authority.button.view"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "depoErrorCorrection",
  data() {
    return {
      flag: false,

      index: 0,
      total: 0,

      pageSize: 15,
      pageNum: 1,
      loading: false,
      areaList: [],
      areaList2: [],
      parkType: [],
      tableCols: [
        {
          prop: "parkCode",
          label: "道路/车场编码",
          width: "",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: "",
        },
        {
          prop: "parkStateDesc",
          label: this.$t("list.Type_of_parking_lot"),
          width: "",
          // formatter: (row) => {
          //   // return this.parkType.find(v => v.code === row.parkType).desc
          // },
        },
        {
          prop: "cityName",
          label: this.$t("list.region"),
          width: "",
          // formatter: (row, column) => {
          //   if (row.plateNumber.indexOf("无") != -1) {
          //     return "无牌车";
          //   } else {
          //     return row.plateNumber;
          //   }
          // },
        },
        {
          prop: "correctionTypeDesc",
          label: "报错类型",
          width: "350",
          // formatter: (row, column) => {
          //   if (row.shouldPay) {
          //     return Number(row.shouldPay / 100).toFixed(2);
          //   } else {
          //     return "0.00";
          //   }
          // },
        },
      ],
      areaIds: "",
      tableData: [],
      formInline: {
        areaId: "",
        streetId: "",
        parkId: "",
        correctionType: "",
      },
    };
  },
  methods: {
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    resetForm() {
      this.formInline = {
        areaId: "",
        streetId: "",
        parkId: "",
        correctionType: "",
      };
      this.$refs.parkInput.setValue("");
      this.$refs.cascader.clear();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        // this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        // this.formInline.parkName = "";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    // 区域的接口
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    detailShow(row) {
      this.$router.replace({
        path: "/depoErrorDetail",
        query: {
          correctionRecordId: row.correctionRecordId,
        },
      });
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // 获取默认情况下的各种下拉列表
    getEooerType() {
      this.$axios.get("/acb/2.0/parkCorrectionRecord/correctionType").then((res) => {
        // console.log("res", res);
        this.parkType = res.value;
      });
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    searParkRecordList() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkCorrectionRecord/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          areaId: areaId,
          parkId: this.formInline.parkId,
          correctionType: this.formInline.correctionType,
        },
        success: (res) => {
          if (res.state === 0) {
            this.tableData = res.value.list;
            // console.log("res.value.total", res.value.total);
            this.total = parseInt(res.value.total);
          }
        },
        fail: () => {
          // this.loading = false;
        },
      };
      this.$request(opt);
    },
  },
  components: {
    myComponent,
  },
  created() {
    this.getArea();
    this.getEooerType();
    this.searParkRecordList();
  },
  mounted() {
    // console.log("按钮权限为==>", this.$route.meta.authority);
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
